<template>
	<b-modal id="view-modal" hide-footer  hide-header no-close-on-backdrop size="md" style="top:30%;" class="text-center">
		<div class="d-flex flex-column text-center justify-content-center"> 
      <img :src="`data:image/png;base64,${asset}`" class="mx-auto"/>
			<button class="btn btn-main mx-auto" @click="close">CLOSE</button>
		</div>
	</b-modal>
</template>

<script>

import qs from 'querystring' 
import numeral from 'numeral' 
export default {
  name: 'ExportImportFund',
  props: ["asset"],
  components: {
  },
  data: function() {
      return  {
      	
      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
    	
   	},
   	methods: {
		 close: function(){
   				this.$bvModal.hide('view-modal'); 
   		},
   	},
   	mounted(){
   		/* this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
	    
	    })*/
   		
 		},
}
</script>
 
<style scoped lang="scss">
	 
  button {
  	margin-top:1em;
  }

   .error {
  	color: red;
   }

   p {
	   margin-top:0px;
	   padding-top:0px;
   }

   h5 {
	   font-weight:bold;
	   margin-top:5px;
   }

   .form-control {
	   background-color: #ccc;
   }

  .email-or-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em;

      .btn {
        color: #47525b;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 0;
        &.active {
          color: #d8962c;
          border-bottom: 1px solid #d8962c;
        }
      }
 
    }

	.btn-main {
   width:40%;
 }

	@media screen and (max-width:1000px) {
		div {
			font-size:0.86em;
		}

		div h5 {
			font-size:1em;
		}
	}
	
</style>
