<template>
	<b-modal id="message-modal" hide-footer  hide-header-close no-close-on-backdrop size="md" >

		<template #modal-header> 
      
			<div>
				
			</div>
			<h5>
				
			</h5> 
			<div></div>

    </template>


		<div> 
			<div class="text-center">
				<h3>{{ message }}</h3>
			</div>
			
			<button class="btn btn-main mt-3" @click="close">OK</button>
		</div>
	</b-modal>
</template>

<script>

import qs from 'querystring' 
export default {
  name: 'ExportImportFund',
  props: ["message"],
  data: function() {
      return  {
      	state: null,
		isSaving: false,
		idNumber: "",
		description: "",
		file: null,
		errors: {
			profile: {
				msg: ''
			}
		}
      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
   	},
   	methods: {
		copy: function(){
   			var copy = document.getElementById("sharingKey");

			copy.select();
			copy.setSelectionRange(0, 99999);

			document.execCommand("copy"); 
   		},

   		close: function(){
   			this.$router.push('/')
   		},	
   	},
   	mounted(){
   		/* this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
	    
	    })*/
 	},
}
</script>
 
<style scoped lang="scss">

   .error {
  	color: red;
   }

   .form-control {
	   background-color: #ccc;
   }

  .email-or-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em;

      .btn {
        color: #47525b;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 0;
        &.active {
          color: #d8962c;
          border-bottom: 1px solid #d8962c;
        }
      }
 
    }
	
</style>
