<template>
    <div class="main-container">
        <img :src="require('../assets/bg2.jpg')" class="bg">
        <div id="loading" v-if="showLoading">
            <div class="content">
        
                <v-icon name="spinner" pulse scale="3" />

            </div>
        </div>
        <div class="d-flex flex-wrap wrapper">
            <div class="sidebar" v-if="sideBar">
                <div class="align-items-end justify-content-end text-right text-white">
                    <h4 style="cursor:pointer;" @click="openSidebar">x</h4>
                </div>
                <div class="text-center">
                    <img :src="require('../assets/id-logo.png')" style="margin-bottom:40px;width:15em;" class="mx-auto text-center">
                </div>
                <div class="sidebar-link d-flex flex-wrap " :class="{active: modalState == 'main'}">
                    <img :src="require('../assets/icon-dash.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Dashboard</h4>
                </div>
                <div class="sidebar-link d-flex flex-wrap" @click="profile">
                    <img :src="require('../assets/profile.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Profile</h4>
                </div>
                <!-- <div class="sidebar-link d-flex flex-wrap" @click="transactions">
                    <img :src="require('../assets/transactions.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Requests</h4>
                </div> -->
                <div class="sidebar-link d-flex flex-wrap" :class="{active: modalState == 'changePin'}" @click="changePin">
                    <img :src="require('../assets/change pipn.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Change Pin</h4> 
                </div>
                <div class="sidebar-link d-flex flex-wrap">
                    <img :src="require('../assets/support.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Support</h4>
                </div>
            </div>
            <div class="main-body">
                <div class="topbar d-flex flex-wrap justify-content-between mb-5">
                    <div class="d-flex justify-content-between">
                        <button class="sidebar-toggler text-white my-auto mr-3" type="button"  @click="dashboard">
                            <v-icon name="chevron-right" />
                        </button>
                        <input class="form-control mr-sm-2 search-bar my-auto" type="search" placeholder="Search Profile ID" aria-label="Search">
                    </div>
                    <div class="left-section d-flex flex-wrap justify-content-end align-items-center text-white">
                        <h6 class="my-auto" style="margin-right:3em;cursor:pointer;" @click="logout">Logout</h6>
                        <img :src="require('../assets/notif.png')" alt="" class="topbar-img my-auto">
                    </div>
                </div>
                <div class="main-wallet" v-if="state == 'main-wallet'">
                    <h3>Verify This Person</h3>
                    <div class="d-flex flex-wrap justify-content-between" style="width:100%;">
                        <div style="width:700px;" class="text-white mt-4">
                            <h5>List of Data Assets</h5>
                            <div v-for="(assets, index) in assetsList">
                                <!-- <div class="text-center" v-if="assets.img != null">
                                    <img :src="`data:image/png;base64,${assets.img}`" class="mx-auto"/>
                                </div> -->
                                <div class="license-border mt-5">
                                    <div class="license-box text-white">
                                        <h6 v-if="assets.type != 'GENDER'"><span style="font-weight:bold;">Government ID:</span> {{ assets.type }}</h6>
                                        <h6 v-if="assets.idNumber != assets.type || assets.type != 'GENDER'"><span style="font-weight:bold;">ID Number:</span> {{ assets.idNumber }}</h6>
                                        <h4 v-if="assets.type == 'GENDER'"><span style="font-weight:bold;"> {{ assets.type }} </span>: {{ assets.value }}</h4>

                                        <button class="btn btn-main mt-4" @click="viewImage(assets.img)">VIEW IMAGE</button>

                                        <div class="btn mt-4 text-center text-white" style="width:100%;background-color:#1f9e09;" v-if="isVerifiedAsset[index] == 'verified'">VERIFIED</div>
                                        <div class="btn mt-4 text-center text-white" style="width:100%;background-color:#9e0e0e;" v-if="isRejectedAsset[index] == 'rejected'">REJECTED</div>

                                        <div class="d-flex flex-wrap justify-content-between mt-4" style="width:100%;" v-if="!isRejectingAsset[index] && isVerifiedAsset[index] == '' && isRejectedAsset[index] == ''">
                                            <button class="btn btn-cancel" style="width:14em;" @click="initRejectAsset(index)">Reject</button>
                                            <button class="btn btn-verify" style="width:14em;" @click="verifyId(assets.type, index)">Verify</button>
                                        </div>

                                        <div class="d-flex flex-wrap justify-content-between mt-4" style="width:100%;" v-if="!isRejectingAsset[index] && isVerifiedAsset[index] == 'verifying' && isRejectedAsset[index] == ''">
                                            <button class="btn btn-cancel" style="width:14em;" disabled>Reject</button>
                                            <button class="btn btn-verify" style="width:14em;" disabled>Verifying...</button>
                                        </div>

                                        <div v-if="isRejectingAsset[index] == true">
                                            <div class="form-group mt-4 text-white" style="width:100%;">
                                                <label for="exampleFormControlTextarea1">Comment</label>
                                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" style="color:white;" v-model="comments[index]"></textarea>
                                            </div>
                                            <div class="d-flex flex-wrap justify-content-between mt-4" style="width:100%;" v-if="isRejectedAsset[index] == ''">
                                                <button class="btn btn-close" style="width:14em;" @click="cancelAsset(index)">Cancel</button>
                                                <button class="btn btn-cancel" style="width:14em;" @click="rejectId(assets.type, index)">Reject</button>
                                            </div>
                                            <div class="d-flex flex-wrap justify-content-between mt-4" style="width:100%;" v-if="isRejectedAsset[index] == 'rejecting'">
                                                <button class="btn btn-close" style="width:14em;" disabled>Cancel</button>
                                                <button class="btn btn-cancel" style="width:14em;" disabled>Rejecting...</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="width:800px;" class="mt-3">
                            <!-- <div class="text-center" v-if="currentAsset.img != null">
                                <img :src="`data:image/png;base64,${currentAsset.img}`" class="mx-auto"/>
                            </div>
                            <div class="license-border mt-5">
                                <div class="license-box text-white">
                                    <h6 v-if="currentAsset.type != 'GENDER'"><span style="font-weight:bold;">Government ID:</span> {{ currentAsset.type }}</h6>
                                    <h6 v-if="currentAsset.idNumber != currentAsset.type || currentAsset.type != 'GENDER'"><span style="font-weight:bold;">ID Number:</span> {{ currentAsset.idNumber }}</h6>
                                    <h4 v-if="currentAsset.type == 'GENDER'"><span style="font-weight:bold;"> {{ currentAsset.type }} </span>: {{ currentAsset.value }}</h4>
                                </div>
                            </div> -->
                            <div class="webview-id-card mb-3">
                                <div class="d-flex flex-wrap justify-content-between mb-4">
                                    <img :src="require('../assets/id-logo.png')" style="width:15em;">
                                    <!-- <div class="text-right my-auto">
                                        <h6 style="color:#0567d7;">BLOCK ID NO.</h6>
                                        <h5 style="color:white;">{{ init.profile.entityId }}</h5>
                                    </div> -->
                                </div>
                                <div class="d-flex flex-wrap justify-content-between">
                                    <div style="width:15%;">
                                        <img :src="`data:image/png;base64,${this.assetsList[0].img}`" style="width:100%;" v-if="assetsList[0]">
                                    </div>
                                    <div style="width:65%;">
                                        <h1 class="last-name" style="color:#0567d7;margin-bottom:0;font-weight:bold;">{{ keyProfile.lastName }}</h1>
                                        <h1 class="first-name" style="color:#0567d7;">{{ keyProfile.firstName }}</h1>
                                        <div class="d-flex flex-wrap justify-content-between mt-3">
                                            <div>
                                                <h6 style="color:#fff;margin-bottom:0;">DATE OF BIRTH</h6>
                                                <h4 style="color:#0567d7;">{{ keyProfile.birthYear }}-{{ keyProfile.birthMm }}-{{ keyProfile.birthDd }}</h4>
                                            </div>
                                            <div>
                                                <h6 style="color:#fff;margin-bottom:0;">GENDER</h6>
                                                <h4 style="color:#0567d7;">{{ keyProfile.gender }}</h4>
                                            </div>
                                            <div>
                                                <h6 style="color:#fff;margin-bottom:0;">CITIZENSHIP</h6>
                                                <h4 style="color:#0567d7;">{{ keyProfile.citizenship }}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width:15%;">
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap justify-content-between mt-4" style="width:100%;" v-if="!isRejected">
                                <button class="btn btn-cancel" style="width:14em;" @click="initReject()">Reject</button>
                                <button class="btn btn-verify" style="width:14em;" @click="verifyUser()">Verify</button>
                            </div>
                            <div v-if="isRejected">
                                <div class="form-group mt-4 text-white" style="width:100%;">
                                    <label for="exampleFormControlTextarea1">Comment</label>
                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" style="color:white;" v-model="userComment"></textarea>
                                </div>
                                <div class="d-flex flex-wrap justify-content-between mt-4" style="width:100%;">
                                    <button class="btn btn-close" style="width:14em;" @click="cancel()">Cancel</button>
                                    <button class="btn btn-cancel" style="width:14em;" @click="rejectUser()">Reject</button>
                                </div>
                            </div>
                            <!-- <div class="text-white text-center" style="width:35em;">
                                <h6>Generate QR Code</h6>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ChangePin/>
        <ConfirmLogout /> 
        <ViewAsset :asset="assetViewing" />
        <MessageModal :message="message" />
    </div>
</template>

<script>
import ChangePin from '@/components/modal/ChangePin.vue'
import ConfirmLogout from '@/components/modal/ConfirmLogout.vue'
import ViewAsset from '@/components/modal/ViewAsset.vue'
import MessageModal from '@/components/modal/MessageModal.vue'
    export default {
    components: {
        ChangePin,
        ConfirmLogout,
        ViewAsset,
        MessageModal
    },
    data() {
        return {
            sideBar: false,
            modalState: 'main',
            state: 'main-wallet',
            showLoading: false,
            assetsList: {},
            keyProfile: {},
            index: 0,
            currentAsset: {},
            isRejected: false,
            isRejectingAsset: [],
            isRejectedAsset: [],
            isVerifiedAsset: [],
            assetViewing: '',
            message: '',
            comments: [],
            userComment: ''
        }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },
    },
    methods: {
        viewImage(asset) {
            this.assetViewing = asset
            this.$bvModal.show('view-modal'); 
        },

        verifyId: function(type, index) {
    
            const params = {
                key: this.$route.params.key,
                type: type
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');


            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/chain/verifyid',
            };

            this.axios(options)
                .then(() => { 
                    this.$set(this.isVerifiedAsset, index, true);
                }).catch((err) => {
                    if(err.response.data.msgCode == 'INVALID.KEY') {
                        this.message = 'This user is invalid.'
                        this.$bvModal.show('message-modal'); 
                    }
                })

        },

        verifyUser: function() {
    
    const params = {
        key: this.$route.params.key,
    }

    const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');


    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data,
        url: '/cx/chain/verifyuser',
    };

    this.axios(options)
        .then(() => { 
            this.message = 'Successfully verified user.'
            this.$bvModal.show('message-modal'); 
        }).catch((err) => {
            if(err.response.data.msgCode == 'INVALID.KEY') {
                        this.message = 'This user is invalid.'
                        this.$bvModal.show('message-modal'); 
                    }
        })

},

        initReject() {
            this.isRejected = true
        },

        initRejectAsset(index) {
            this.$set(this.isRejectingAsset, index, true);
            console.log(this.isRejectingAsset[index], index)
        },

        cancel() {
            this.isRejected = false
        },

        cancelAsset(index) {
            this.$set(this.isRejectingAsset, index, false);
            console.log(this.isRejectingAsset[index], index)
        },
        rejectId(type, index) {
            this.$set(this.isRejectedAsset, index, 'rejecting');
            const params = {
                key: this.$route.params.key,
                type: type,
                remarks: this.comments[index]
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');


            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/chain/rejectid',
            };

            this.axios(options)
                .then(() => { 
                    this.$set(this.isRejectingAsset, index, false);
                    this.$set(this.isRejectedAsset, index, 'rejected');
                }).catch((err) => {
                    if(err.response.data.msgCode == 'INVALID.KEY') {
                        this.message = 'This user is invalid.'
                        this.$bvModal.show('message-modal'); 
                    }
                })
        },

        rejectUser() {
            const params = {
                key: this.$route.params.key,
                remarks: this.userComment
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');


            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/chain/rejectuser',
            };

            this.axios(options)
                .then(() => { 
                    this.message = 'Successfully rejected user.'
                    this.$bvModal.show('message-modal'); 
                }).catch((err) => {
                    if(err.response.data.msgCode == 'INVALID.KEY') {
                        this.message = 'This user is invalid.'
                        this.$bvModal.show('message-modal'); 
                    }
                })
        },

        formatAmount:  function (num) {
            if(isNaN(num)) {
            num = 0;
            }
            return parseFloat(num).toFixed(8)
        },
        dashboard() { 
            this.$router.push('/')
        },
        formatDate: function(time){
            return new Date(time).toLocaleString();
        },
        logout: function(){
            this.$bvModal.show('logout-modal'); 
        },
        getAssets: function() {
            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/chain/keydetails?key=' + this.$route.params.key,
            };

            this.axios(options)
                .then((response) => { 
                    this.keyProfile = response.data.profile
                    this.assetsList = response.data.dataAsset

                    console.log(this.assetsList[0])

                    let objectLength = Object.keys(this.assetsList).length;

                    for (let i = 0; i < objectLength; i++) {
                        this.$set(this.isRejectingAsset, i, false);
                        this.$set(this.isVerifiedAsset, i, false);
                        this.$set(this.isRejectedAsset, i, false);
                        this.$set(this.comments, i, "");
                    }

                    console.log(this.isRejectingAsset);

                    this.currentAsset = response.data.dataAsset[this.index]
                    console.log(this.currentAsset)
                }).catch((err) => {
                    if(err.response.data.msgCode == 'INVALID.KEY') {
                        this.message = 'This user is invalid.'
                        this.$bvModal.show('message-modal'); 
                    }

                })
        },
        getProfile: function(){
            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/profile/',
            };

            this.axios(options)
                .then((response) => {
                this.init.profile = response.data;
                if(this.init.profile.mobileNumber == null) {
                    this.hasMobile = false
                } else {
                    this.hasMobile = true
                } 
            })
        },
        checkSession: function() {
            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/sessioncheck',
            };

            this.axios(options)
            .then((response) => { 
                this.getProfile();
                }).catch((err) => {
                    
                if(err.response.data.msgCode == '00001') {
                    window.location.href = "/login";
                }  

                if(err.response.data.msgCode == '00020') {

                    setTimeout( () => {
                    window.location.href = "/setup-profile";
                },1000)
                    
                }  

                })
        },
    },
    beforeMount() {
        this.checkSession();
        let socketScript = document.createElement('script')
            socketScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/socket.io/2.3.0/socket.io.js')
            document.head.appendChild(socketScript)
        this.getAssets();
        this.getProfile();
    }
}
</script>

<style scoped lang="scss">
    .wrapper {
        position:relative;
        .sidebar {
            position:fixed;
            padding:20px;
            width:20em;
            background-color:#000;
            height:100vh;
            z-index:2;
            .sidebar-link {
                margin:20px auto;
                width: 15em;
                cursor:pointer;
                &.active {
                    border-right:2px #0e649e solid;
                }
                .sidebar-link-img {
                    width:20px;
                    object-fit:contain;
                    height:auto;
                    margin-right:4px;
                }
                .sidebar-link-text {
                    color:white;
                    margin-left:4px;
                    margin-top:13px;
                }
                .arrow-down {
                    width:15px;
                    object-fit:contain;
                    height:auto;
                }
            }
        }
        .key-choices {
            position: absolute;
            background: #fff;
            color: #333;
            top: 60px;
            overflow: auto;
            z-index: 3;
            width:100%;
            border-radius:10px;
            .choice {
                width:100%;
                padding:10px;
                &:hover {
                    background-color:blue;
                    color:white;
                }
            }
        }
        .main-body {
            width:100%;
            padding:50px 70px;
            .sidebar-toggler {
                background:none;
                width:2.5em;
                height:2.5em;
                border:#fff 1px solid;
                border-radius:5px;
                fill:white;
            }
            .search-bar {
                width:20em;
            }
            .left-section {
                .topbar-img {
                    object-fit: contain;
                }
                .profile {
                    .profile-name {
                        color:white;
                        margin-bottom:0;
                        font-family: 'SSN-Bold';
                    }
                    .profile-email {
                        color:white;
                        font-family: 'SSN-Light';
                        margin-bottom:0;
                    }
                }
            }
            .tabs {
                width: 50em;
                .tab {
                    cursor:pointer;
                    color:#fff;
                    font-family:SSN-Roman;
                    &.active {
                        color:#0567d7;
                    }
                    h6 {
                        margin-bottom:2px;
                    }
                }
                .active-circle {
                    width:10px;
                    height:10px;
                    background: #0567d7;
                    border-radius:50%;
                }
            }
            .main-wallet {
                width:100%;
                h3 {
                    color:#3297db;
                }
                .license-border {
                    width:35em;
                    background:linear-gradient(to right, #0e649e, #fff700, #9e0e0e);
                    border-radius:5px;
                    padding:2px;
                    .license-box {
                        background:#062035;
                        padding:15px;
                        border-radius:5px;
                    }
                }
                .btn-cancel {
                    border:2px #9e0e0e solid;
                    color:white;
                    border-radius:5px;
                }
                .btn-close {
                    border:2px gray solid;
                    color:white;
                    border-radius:5px;
                }
                .btn-verify {
                    border:2px #1f9e09 solid;
                    color:white;
                    border-radius:5px;
                }
                textarea {
                    background:transparent;
                    border:2px #0e649e solid;
                }
                .green-radio {
                    width:1em;
                    height:1em;
                    accent-color: #1f9e09;
                }
                .red-radio {
                    width:1em;
                    height:1em;
                    accent-color: #9e0e0e;
                }
                .data-input {
                    width:15em;
                    background:none;
                    border:2px #0e649e solid;
                    color:white;
                }
            }
        }
    }

    .webview-id-card {
        width:100%;
        background-image: url('../assets/ID-bg.png');
        background-size:cover;
        background-repeat:no-repeat;
        border-radius:10px;
        padding:20px;
    }
</style>